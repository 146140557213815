
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class ColorPicker extends mixins(StyleCheck) {
  @Prop({ default: "Color" }) label!: string;
  @Prop({ default: "#1976D2FF" }) color!: string;
  @Prop({ default: "LIGHT" }) mode!: string;
  @Prop({ default: false }) hide_letters!: boolean;
  menu = false;
  value = "#1976D2FF";

  private get swatchStyle() {
    const { value, menu } = this;
    return {
      backgroundColor: value,
      cursor: "pointer",
      height: "25px",
      width: "25px",
      borderRadius: menu ? "50%" : "4px",
      border: "1px solid gray",
      transition: "border-radius 200ms ease-in-out",
    };
  }

  mounted() {
    this.value = this.color;
  }

  @Watch("value")
  changeColor() {
    this.$emit("change", this.value);
  }
}
